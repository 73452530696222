import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/lib/store/store";
import { TestState } from "./test.type";

const initialState: TestState = {
  result: null,
  questions: [],
};

const slice = createSlice({
  name: "test",
  initialState,
  reducers: {
    addResult: (state, { payload }) => {
      state.result = payload;
    },
    addQuestions: (state, { payload }) => {
      state.questions = payload;
    },
    // removeSelectedJob: (state) => {
    //   return { ...state, selectedJob: null };
    // },
  },
});

export const { addResult, addQuestions } = slice.actions;
export default slice.reducer;

export const selectTestState: (state: RootState) => TestState = (
  state: RootState
) => state.test;
