import { AxiosError } from "axios";
import axiosInstance from "src/lib/config/axios";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { GenericResponse, Question, Result } from "./question.type";

export const useRandomizedQuestionQuery = () => {
  const { data, error, isLoading, mutate } = useSWR<
    Question[],
    AxiosError,
    string
  >(`/dev/getMyRandomizedQuestion`, (url: string) =>
    axiosInstance
      .get(url)
      .then((res: any) =>
        res.data.data.filter((each: Question[]) => each.length !== 0)
      )
  );

  return {
    questions: data,
    isLoading,
    error,
    getQuestions: mutate,
  };
};

export const useTestDurationQuery = () => {
  const { data, error, isLoading, mutate } = useSWR<
    GenericResponse,
    AxiosError,
    string
  >(`/dev/getMyTestDuration`, (url: string) =>
    axiosInstance.get(url).then((res: any) => res.data.data)
  );

  return {
    testDuration: data,
    isLoading,
    error,
    getTestduration: mutate,
  };
};

export const useResultQuery = () => {
  const { data, error, isLoading, mutate } = useSWR<any, AxiosError, string>(
    `/dev/getMyResult`,
    (url: string) => axiosInstance.get(url).then((res: any) => res.data.data[0])
  );

  return {
    result: data,
    isLoading,
    error,
    getResult: mutate,
  };
};

export const useAnswerQuestionMutation = (id: string) => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    GenericResponse,
    AxiosError,
    `/dev/answerQuestion/droomwork`,
    FormData,
    any
  >(`/dev/answerQuestion/droomwork`, (url: string, { arg }) =>
    axiosInstance.put(url, arg).then((res) => res.data)
  );

  return {
    data,
    isLoading: isMutating,
    submitAnswer: trigger,
  };
};

export const useSubmitExamMutation = () => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    GenericResponse,
    AxiosError,
    `/dev/submitExam/droomwork`,
    any,
    any
  >(`/dev/submitExam/droomwork`, (url: string, { arg }) =>
    axiosInstance.put(url, arg).then((res) => res.data)
  );

  return {
    data,
    isLoading: isMutating,
    submitExam: trigger,
  };
};
