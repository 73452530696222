
const letters = ['A', 'B', 'C', 'D', 'E']

const MultiChoiceRadio = ({ paragraphs, choosenAnswer, setChoosenAnswer }) => {
  return (
    <div className='flex flex-col gap-9 w-full justify-start'>
      {paragraphs.map(([option, paragraph], index) => (
        <div key={option} className='flex gap-4 '>
          <input
            type='radio'
            name='options'
            value={option}
            checked={choosenAnswer === option}
            onChange={(e) => setChoosenAnswer(e.target.value)}
          />
          <label htmlFor='options' onClick={() => setChoosenAnswer(option)} className='flex gap-5 items-center hover:cursor-pointer'>
            <p>{letters[index]}:</p>
            <p>{paragraph}</p>
          </label>
        </div>
      ))}
    </div>
  );
};

export default MultiChoiceRadio;
