import { Fragment, useEffect, useMemo, useState } from "react";
import Countdown, { CountdownRenderProps, zeroPad } from "react-countdown";
import { AiOutlinePushpin } from "react-icons/ai";
import PageLoader from "src/components/Loader/PageLoader";
import {
  useAnswerQuestionMutation,
  useSubmitExamMutation,
  useTestDurationQuery,
} from "src/lib/endpoints/question/question.api";
import { Question } from "src/lib/endpoints/question/question.type";
import { selectTestState } from "src/lib/features/test/testSlice";
import { useTypedSelector } from "src/lib/store/store";
import Button from "../../components/Button/Button";
import TestScoreModal from "../../components/Modal/TestScoreModal";
import MultiChoiceRadio from "../../components/MultiChoice.jsx/MultiChoiceRadio";

const TakeTest = () => {
  const { testDuration } = useTestDurationQuery();
  const [showModal, setShowModal] = useState(false);
  const [questionId, setQuestionId] = useState("");
  const { submitAnswer } = useAnswerQuestionMutation(questionId);
  const { submitExam } = useSubmitExamMutation();
  const { questions } = useTypedSelector(selectTestState);
  const [questionNo, setQuestionNo] = useState(1);
  const [choosenAnswer, setChoosenAnswer] = useState("");
  const [isCompleted, setIscompleted] = useState(false);

  const onSubmitHandler = () => {
    if (isCompleted) return;
    if (questions.length === questionNo) {
      submitExam(
        {},
        {
          onSuccess: () => {
            setShowModal(true);
          },
          onError: () => {},
        }
      );
    } else {
      const formData = new FormData();
      formData.append("questionID", questionId);
      formData.append("option", choosenAnswer);
      submitAnswer(formData, {
        onSuccess: () => {
          setQuestionNo((prev) => prev + 1);
          setChoosenAnswer('')
        },
        onError: () => {},
      });
    }
  };

  const onCloseHandler = () => {
    setShowModal(false);
  };

  const renderer = ({
    hours,
    minutes,
    seconds,
    completed,
  }: CountdownRenderProps) => {
    if (completed) {
      // Render a completed state
      return (
        <span>
          {"00"}:{"00"}
        </span>
      );
      // return <span>You are good to go!</span>;
    } else {
      return (
        <span>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };

  const shuffle = (array: any[]) => {
    return array.sort(() => Math.random() - Math.random());
  };

  const questionDetails = useMemo(() => {
    const question = questions[questionNo - 1] as Question;
    setQuestionId(question?.id ?? "");

    return {
      question: question?.question ?? "",
      paragraphs: shuffle([
        ["correctOption1", question?.correctOption1],
        ["option2", question?.option2],
        ["option3", question?.option3],
        ["option4", question?.option4],
        ["option5", question?.option5],
      ]),
    };
  }, [questionNo]);

  const countDown = useMemo(
    () => (
      <Countdown
        date={Date.now() + +(testDuration || 0) * 60 * 1000}
        renderer={renderer}
        autoStart={true}
        onComplete={({ minutes, seconds }) => {
          if (testDuration === undefined) return;
          setIscompleted(true)
          submitExam(
            {},
            {
              onSuccess: () => {
                console.log("submitted");
                setShowModal(true);
              },
              onError: () => {},
            }
          );
        }}
      />
    ),
    [testDuration]
  );

  useEffect(() => {
    const stopPropagation = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = "Data will be lost if you leave the page, are you sure?";
    };

    window.addEventListener("beforeunload", stopPropagation);

    return () => {
      window.removeEventListener("beforeunload", stopPropagation);
    };
  }, []);

  if (!questions || questions.length === 0 || testDuration === undefined)
    return (
      <Fragment>
        <PageLoader />
      </Fragment>
    );

  return (
    <div>
      <div className="h-full my-auto">
        <TestScoreModal open={showModal} onClose={onCloseHandler} />
        <div className="flex flex-col space-y-10 pt-10 pb-20 items-center ">
          <div className="flex items-center justify-between w-full">
            <div className="flex gap-7 items-center font-medium">
              <AiOutlinePushpin size={21} />
              <p>
                {questionNo}/{questions.length}
              </p>
            </div>
            {/* COUNTDOWN */}
            {countDown}
          </div>
          {/* QUESTION */}
          <h1 className="text-lg font-medium">
            {/* Why is Javascript the king of Language to the browser? */}
            {questionDetails.question}
          </h1>
          <div className="w-full">
            <MultiChoiceRadio
              choosenAnswer={choosenAnswer}
              setChoosenAnswer={setChoosenAnswer}
              paragraphs={questionDetails.paragraphs ?? []}
            />
          </div>
          <div className="flex justify-between items-center w-full">
            <Button
              onClick={() =>
                setQuestionNo((prev) => (prev > 1 ? prev - 1 : prev))
              }
              className={`
                bg-white text-primary
            `}
            >
              Prev
            </Button>
            <Button
              onClick={onSubmitHandler}
              className={`
                bg-primary text-white
            `}
            >
              {questions?.length === questionNo ? "Submit" : "Next"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TakeTest;
