import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { SWRConfig } from "swr";
import { Provider } from "react-redux";
import { store } from "./lib/store/store";

const root = ReactDOM.createRoot(document.getElementById("root") as Element);
root.render(
  <React.StrictMode>
    <SWRConfig
      value={{
        onError: (error, key) => {
          if (error.status !== 403 && error.status !== 404) {
            // console.log('global_error', error)
            // We can send the error to Sentry,
            // or show a notification UI.
          }
        },
      }}
    >
      <Provider store={store}>
        {/* <PersistGate loading={<PageLoader />} persistor={persistor}> */}
        <App />
        {/* </PersistGate> */}
      </Provider>
    </SWRConfig>
  </React.StrictMode>
);
