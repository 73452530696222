import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/Home/LandingPage";
import Results from "./pages/Results";

function App() {
  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/results" element={<Results />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjIsImlhdCI6MTcwOTI5NTcyMX0.P5WOG2in6pRNk4X7fptYv4eFrgR4TbBJ-zj9lBMd44o
