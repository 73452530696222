import React from "react";

type Props = {
  children: JSX.Element | string;
  type?: "button" | "submit" | "reset" | undefined;
  className?: string;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  icon?: any;
};

const Button = ({ children, className, onClick, type = 'button', icon }: Props) => {
  const onClickHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (typeof onClick === "function") {
      e.preventDefault();
      onClick && onClick();
    }
  };

  return (
    <div>
      <button
        type={type}
        className={`${className} text-sm rounded-lg px-[30px] py-3.5 border border-transparent whitespace-nowrap`}
        onClick={onClickHandler}
      >
        {children}
      </button>
    </div>
  );
};

export default Button;
