import React, { useCallback, useEffect, useState } from "react";
import Button from "src/components/Button/Button";
import Divider from "src/components/Divider/Divider";
import { useResultQuery } from "src/lib/endpoints/question/question.api";

type Extras = {
  name?: string;
  points?: number;
  percentage?: number;
};

const Results = () => {
  const { result } = useResultQuery();
  const [frameworks, setFrameworks] = useState<Extras[]>([]);
  const [skills, setSkills] = useState<Extras[]>([]);
  const [languages, setLanguages] = useState<Extras[]>([]);

  const filterDetails = useCallback(() => {
    if (!result) return

    let idx = 1;

    while (
      `language${idx}_name` in result ||
      `skill${idx}_name` in result ||
      `framework${idx}_name` in result
    ) {
      if (`language${idx}_name` in result) {
        const data: Extras = {
          name: result[`language${idx}_name`],
          points: result[`language${idx}_point`],
          percentage: result[`language${idx}_percentage_score`],
        };
        setLanguages((prev) => prev.concat([data]));
      }

      if (`framework${idx}_name` in result) {
        const data: Extras = {
          name: result[`framework${idx}_name`],
          points: result[`framework${idx}_point`],
          percentage: result[`framework${idx}_percentage_score`],
        };
        setFrameworks((prev) => prev.concat([data]));
      }

      if (`skill${idx}_name` in result) {
        const data: Extras = {
          name: result[`skill${idx}_name`],
          points: result[`skill${idx}_point`],
          percentage: result[`skill${idx}_percentage_score`],
        };
        setSkills((prev) => prev.concat([data]));
      }
      idx++;
    }
  }, [result]);

  useEffect(() => {
    filterDetails();
  }, []);

  return (
    <div className="max-w-[80%] lg:max-w-[968px] lg:w-[768px] h-screen py-8 overflow-auto w-full flex flex-col justify-center items-center m-auto">
      <div className="w-full">
        <h2 className="text-xl lg:text-[1.75rem] font-bold">CBT Test Result</h2>
        <Divider className={"mb-10 mt-3"} />
      </div>
      {result && (
        <div className="grid grid-cols-2 gap-[30px] h-[75vh] py-10 overflow-auto md;grid-cols-3 xl:grid-cols-4">
          <div>
            <h3 className="font-semibold leading-8 mb-2.5 md:text-lg lg:text-xl">
              Framework
            </h3>
            <div className="flex flex-col space-y-3">
              {frameworks.map((framework, i) => (
                <div className="inline-grid justify-between grid-cols-2 gap-1">
                  <p className="font-semibold">{framework.name}</p>
                  <p></p>
                  <p>Point:</p>
                  <p>{framework.points}</p>
                  <p>Score:</p>
                  <p>{framework.percentage}</p>
                </div>
              ))}
            </div>
          </div>
          <div>
            <h3 className="font-semibold leading-8 mb-2.5 md:text-lg lg:text-xl">
              Language
            </h3>
            <div className="flex flex-col space-y-2">
              {languages.map((language, i) => (
                <div className="inline-grid justify-between grid-cols-2 gap-1">
                  <p className="font-semibold">{language.name}</p>
                  <p></p>
                  <p>Point:</p>
                  <p>{language.points}</p>
                  <p>Score:</p>
                  <p>{language.percentage}</p>
                </div>
              ))}
            </div>
          </div>
          <div>
            <h3 className="font-semibold leading-8 mb-2.5 md:text-lg lg:text-xl">
              Skills
            </h3>
            <div className="flex flex-col space-y-2">
              {skills.map((skill, i) => (
                <div className="inline-grid justify-between grid-cols-2 gap-1">
                  <p className="font-semibold">{skill.name}</p>
                  <p></p>
                  <p>Point:</p>
                  <p>{skill.points}</p>
                  <p>Score:</p>
                  <p>{skill.percentage}</p>
                </div>
              ))}
            </div>
          </div>
          <div>
            <h3 className="font-semibold leading-8 mb-2.5 md:text-lg lg:text-xl">
              Internet knowledge
            </h3>
            <div className="inline-grid justify-between grid-cols-2 gap-2">
              <p>Point:</p>
              <p>{result?.internet_point}</p>
              <p>Score:</p>
              <p>{result?.internet_score}</p>
            </div>
          </div>
          <div>
            <h3 className="font-semibold leading-8 mb-2.5 md:text-lg lg:text-xl">
              Version control knowledge{" "}
            </h3>
            <div className="inline-grid justify-between grid-cols-2 gap-2">
              <p>Point:</p>
              <p>{result?.version_control_point}</p>
              <p>Score:</p>
              <p>{result?.version_control_score}</p>
            </div>
          </div>
          <div>
            <h3 className="font-semibold leading-8 mb-2.5 md:text-lg lg:text-xl">
              Security knowledge
            </h3>
            <div className="inline-grid justify-between grid-cols-2 gap-2">
              <p>Point:</p>
              <p>{result?.security_point}</p>
              <p>Score:</p>
              <p>{result?.security_score}</p>
            </div>
          </div>
        </div>
      )}
      <Divider className={"mt-7 mb-8"} />
      <div className="flex justify-between items-center w-full">
        <Button className={"text-white bg-primary"}>Share</Button>
        <div className="flex gap-4">
          <p className="text-xl text-primary lg:text-[1.75rem] font-bold">
            Total score:
          </p>
          <p className="text-xl text-primary lg:text-[1.75rem] font-medium">
            {result?.total_score}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Results;
