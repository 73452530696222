import axios from "axios";

export const TOKEN = "droomwork-cbt-token";

const headers = {};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL + "/api/v1",
  timeout: 20000,
  headers,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token")?.trim() ?? localStorage.getItem(TOKEN);
    localStorage.setItem(TOKEN, token ?? "");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
