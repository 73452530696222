import { Fragment, useEffect, useState } from "react";
import TakeTest from "../Test/TakeTest";
import Button from "src/components/Button/Button";
import { useAppDispatch } from "src/lib/store/store";
import { useRandomizedQuestionQuery } from "src/lib/endpoints/question/question.api";
import { addQuestions } from "src/lib/features/test/testSlice";
import PageLoader from "src/components/Loader/PageLoader";

const LandingPage = () => {
  const [startTest, setStartTest] = useState(false);
  const { questions } = useRandomizedQuestionQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!questions || questions.length === 0) return;
    dispatch(addQuestions(questions.flat()));
  }, [questions]);

  if (!questions || questions.length === 0)
    return (
    <Fragment>
      <PageLoader />
    </Fragment>
    );

  return (
    <div className="max-w-[80%] lg:max-w-[968px] lg:w-[768px] h-screen w-full flex justify-center items-center m-auto">
      {!startTest ? (
        <div className="flex flex-col space-y-5 my-auto items-center justify-center">
          <p className="font-bold text-2xl">Ready to take the test?</p>

          <p>{`This test will assess your knowledge and problem-solving skills across various programming areas. Do not leave the page during the test and kindly ensure you are always in front of the camera. 
        Good luck.`}</p>

          <Button
            className="text-white bg-gray-800"
            onClick={() => setStartTest(true)}
          >
            Start
          </Button>
        </div>
      ) : (
        <>
          {!!questions && questions?.length !== 0 ? (
            <TakeTest />
          ) : (
            <Fragment>
              <PageLoader />
            </Fragment>
          )}
        </>
      )}
    </div>
  );
};

export default LandingPage;
